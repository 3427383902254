<template>
    <div>
        <jy-query ref="form" labelWidth="100" class="query_area" size="small">
            <jy-query-item label="产废企业">
                <el-select v-model="form.unitId">
                    <el-option v-for="u in rUnitList" :key="u.unitId" :value="u.unitId" :label="u.cname"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="所属行业">
                <el-select v-model="form.industry">
                    <el-option v-for="p in industryList" :key="p.iValue" :value="p.iValue" :label="p.iValue"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="所属行政区域">
                <div @click="selectRegion">
                    <el-input placeholder="请选择" v-model="form.regionNa"></el-input>
                </div>
            </jy-query-item>
            <jy-query-item label="固废类别">
                <el-select v-model="form.wasteTId">
                    <el-option v-for="t in wasteTypes" :key="t.wasteTId" :label="t.cName" :value="t.wasteTId"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="所属机构" v-if="visible">
                <div @click="selectInstitutions">
                    <el-input placeholder="请选择" v-model="form.orgNa"></el-input>
                </div>
            </jy-query-item>
            <jy-query-item label="分拣中心">
                 <el-select v-model="form.tUnitId">
                    <el-option v-for="u in unitList" :key="u.unitId" :value="u.unitId" :label="u.cname"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="日期">
                <el-date-picker
                    v-model="selectedDate"
                    value-format="yyyy-MM-dd"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :default-time="['00:00:00', '23:59:59']"
                    :clearable="false"
                >
                </el-date-picker>
            </jy-query-item>
            <template slot="rightCol">
                <el-button size="small" type="primary" @click="oncheck">查询</el-button>
                <el-button size="small" type="primary" @click="resetForm('form')">重置</el-button>
            </template>
        </jy-query>
        <div>搜索结果合计：记录{{ total }}条，产废量净重{{ sum }}kg</div>
        <div class="jy-table" v-loading="loading">
            <jy-table :data="dataList">
                <jy-table-column label="序号" type="index" width="55"></jy-table-column>
                <jy-table-column label="产废企业" min-width="140">
                    <template v-slot="scope">{{ scope.row.unitInfo.cName}}</template>
                </jy-table-column>
                <jy-table-column label="所属行业" min-width="120">
                    <template v-slot="scope">{{ scope.row.unitInfo.industry }}</template>
                </jy-table-column>
                <jy-table-column label="企业所属行政地区" min-width="150">
                    <template v-slot="scope">{{ scope.row.unitInfo.regionNa }}</template>
                </jy-table-column>
                <jy-table-column label="固废类别" min-width="120">
                    <template v-slot="scope">{{ scope.row.wasteTNa }}</template>
                </jy-table-column>
                <jy-table-column label="所属机构" v-if="visible" min-width="140">
                    <template v-slot="scope">{{ scope.row.orgNa }}</template>
                </jy-table-column>
                <jy-table-column label="分拣中心" min-width="120">
                    <template v-slot="scope">{{ formatUnit(scope.row.tUnitId) }}</template>
                </jy-table-column>
                <jy-table-column label="毛重过磅时间" min-width="160">
                    <template v-slot="scope">{{ scope.row.grossWT }}</template>
                </jy-table-column>
                <jy-table-column :label="'毛重(' + unit + ')'" min-width="120">
                    <template v-slot="scope">{{ scope.row.grossW }}</template>
                </jy-table-column>
                <jy-table-column label="皮重过磅时间" min-width="160">
                    <template v-slot="scope">{{ scope.row.tareWT }}</template>
                </jy-table-column>
                <jy-table-column :label="`皮重(${unit})`" min-width="120">
                    <template v-slot="scope">{{ scope.row.tareW }}</template>
                </jy-table-column>
                <jy-table-column :label="`实际净重(${unit})`" min-width="120">
                    <template v-slot="scope">{{ formatTotalWeight(scope.row) }}</template>
                </jy-table-column>
            </jy-table>
            <jy-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageIndex"
                :page-size="pageSize"
                :total="total"
            ></jy-pagination>
        </div>

        <region-tree ref="region" @select="addRegion"></region-tree>

        <institutions-tree
            refresh
            :showType="showType"
            :expended="expended"
            :modal="false"
            ref="institutions"
            @addInstitutions="addInstitutions"
            title="选择所属机构"
        ></institutions-tree>
    </div>
</template>

<script>
    import RegionTree from "@/components/pages/admin/common/dialog-tree/region_tree.vue";
    import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";

    export default {
        async created() {
            await this.initData();
            this.getList();
        },
        methods: {
            async initData() {
                this.initTime();
                this.getUnitList();
                this.getRUnitList();
                this.getIndustryList();
                await this.getWasteTypeList();
            },
            initTime() {
                const today = new Date();
                const sevenDaysBefore = new Date(today);
                sevenDaysBefore.setDate(today.getDate() - 7);
                this.selectedDate = [sevenDaysBefore, today];
            },
            oncheck() {
                this.pageIndex = 1;
                this.getList();
            },
            getList() {
                this.loading = true;
                this.$http
                    .post("/stat/unit/wastes/dayStat", {
                        ...this.form,
                        pageIndex: this.pageIndex,
                        pageSize: this.pageSize,
                    })
                    .then(res => {
                        this.dataList = res.detail.list;
                        this.total = res.detail.total;
                        this.unit = this.wasteTypes.find(each => each.wasteTId === this.form.wasteTId).unit;
                    })
                    .catch(e => {
                        console.log(e);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            async getWasteTypeList() {
                return this.$http
                    .post("/wasteType/listAll")
                    .then(res => {
                        this.wasteTypes = res.detail;
                        this.form.wasteTId = this.wasteTypes[0].wasteTId;
                        this.unit = this.wasteTypes[0].unit;
                    });
            },
            async getUnitList() {
                return this.$http
                    .post("/unitInfo/queryUnitList", {
                        cName: "",
                        unitType: "3"
                    })
                    .then(res => {
                        this.unitList = res.detail;
                    });
            },
            async getRUnitList() {
                return this.$http
                    .post("/unitInfo/queryUnitList", {
                        cName: "",
                        unitType: "0",
                        contractWorkT: this.$util.parseTime(new Date(), "yyyy-MM-dd hh:mm:ss")
                    })
                    .then(res => {
                        this.rUnitList = res.detail;
                    });
            },
            async getIndustryList() {
                 return this.$http.post("/dict/getDictValuesByDictId", "dictindustry").then(res => {
                    this.industryList = res.detail;
                });
            },
            add() {
                this.$emit("switch");
            },
            addRegion(data) {
                this.form.regionId = data.regionId;
                this.form.regionNa = data.regionNa;
                this.level = data.level;
            },
            addInstitutions(data) {
                this.form.orgId = data.orgId;
                this.form.orgNa = data.orgNa;
            },
            // 重置
            async resetForm(formName) {
                Object.keys(this.form).map(key => {
                    this.form[key] = null;
                });
                this.$refs[formName].resetFields();
                await this.initData();
                this.oncheck();
            },
            // 分页
            handleSizeChange(val) {
                this.pageSize = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.getList();
            },
            selectRegion() {
                this.$refs.region.init();
            },
            selectInstitutions() {
                this.showType = [1];
                this.expended = false;
                this.$refs.institutions.init({ flag: "org" });
            },
            formatUnit(key) {
                let unitList = this.unitList;
                const u = unitList.find(v => v.unitId === key);
                return u ? u.cname : "-";
            },
            formatNumber(v1, v2) {
                if (isNaN(v1) || isNaN(v2)) {
                    return "-";
                }
                return (v1 - v2).toFixed(2);
            },
            formatTotalWeight(row){
                let r = row.rWeight || 0
                let g = row.grossW || 0
                let t = row.tareW || 0
                return r+g-t
            }
        },
        watch: {
            selectedDate(newValue) {
                [this.form.dateStart, this.form.dateEnd] = newValue;
            }
        },
        computed: {
            sum() {
                return this.dataList.reduce((prev, cur) => {
                    return prev + (cur.grossW - cur.tareW);
                }, 0);
            },
            visible() {
                const uType = this.$store.state.info.uType;

                if (typeof uType === 'number' && uType === 2) {
                    return false;
                }

                return true;
            }
        },
        data() {
            return {
                loading: false,
                dataList: [],
                total: 0,
                showType: [0, 1, 2],
                expended: true,

                selectedDate: ['', ''],

                unit: '',
                form: {
                    unitId: null,
                    tUnitId: null,
                    industry: null,
                    orgNa: null,
                    orgId: null,
                    regionNa: null,
                    regionId: null,
                    wasteTId: null,
                    dateStart: null,
                    dateEnd: null,
                },

                unitList: [],
                rUnitList: [],
                industryList: [],
                wasteTypes: [],

                pageIndex: 1,
                pageSize: 10
            };
        },
        components: {
            RegionTree,
            institutionsTree,
        }
    };
</script>
<style scoped>
    .query_area {
        margin-top: 10px;
    }
</style>
